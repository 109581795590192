export const EMPLOYEE_CONTEXT_URL = '/employee/v1.0';
export const TEAMS_CONTEXT_URL = '/empl/teams/v1.0';
export const MS_TEAMS_ONE_DRIVE_CONTEXT = '/empl/teams/v1.0/drive';
export const ATTENDANCE_CONTEXT_URL = '/attendance/v1.0';
export const ATTENDANCE_CONTEXT_URL_V2 = '/attendance/v2.0';
export const NOTIFICATION_CONTEXT_URL = '/notifications/v1.0';
export const NOTIFICATION_CONTEXT_URL_V2 = '/notifications/v2.0';
export const LEASE_CONTEXT_URL = '/lease/v1.0';
export const SHAREPOINT_URL = '/employee/v1.0/sharePoint';

// Profile
export const PROFILE = (): string => `${EMPLOYEE_CONTEXT_URL}/profile`;
export const USER_PROFILE = (email: string) => `${PROFILE()}/other/${email}`;
export const USER_PROFILE_EDR = (email: string) => `${PROFILE()}/directory/details/${email}`;
export const GENERAL_INFO = (hcmPersonId: string): string =>
  `${PROFILE()}/${hcmPersonId}/generalInfo`;
export const HIERARCHY = (hcmPersonId: string): string => `${PROFILE()}/${hcmPersonId}/hierarchy`;
export const PICTURE = (hcmPictureId: string): string => `${PROFILE()}/${hcmPictureId}/picture`;
export const SUBORDINATES = (): string => `${PROFILE()}/hierarchy-nodes`;
export const EXPENSES = (hcmPersonId: string) => `${PROFILE()}/${hcmPersonId}/expenses`;
export const EXPENSES_ATTACHMENT = (hcmPersonId: string, expensesUniqID: string) =>
  `${PROFILE()}/${hcmPersonId}/expenses/${expensesUniqID}/attachments`;
export const BALANCE_INFO = (hcmPersonId: string): string =>
  `${PROFILE()}/${hcmPersonId}/leave/balance/available`;
export const LEAVES_HISTORY = (hcmPersonId: string): string =>
  `${PROFILE()}/${hcmPersonId}/absences`;
export const PAYSLIP = (hcmPersonId: string | null, year: string): string =>
  `${PROFILE()}/${hcmPersonId}/payslips/${year}`;
export const PAYSLIP_PDF_FILE = (hcmPersonId: string, month: string, year: string): string =>
  `${PROFILE()}/${hcmPersonId}/payslips/report/${month}/${year}`;

// Teams
export const TEAMS = (): string => `${TEAMS_CONTEXT_URL}/teams`;
export const TEAMS_USER_AVATAR = (userId: string): string =>
  `${TEAMS_CONTEXT_URL}/user/${userId}/photo`;
export const TEAMS_USER_AVATAR_BY_EMAIL = () => `${TEAMS_CONTEXT_URL}/user/photo`;
export const TEAMS_CHANNELS = (teamId: string): string => `${TEAMS()}/${teamId}/channels`;
export const TEAMS_CHANNEL_MEMBERS = (teamId: string, channelId: string) =>
  `${TEAMS_CHANNELS(teamId)}/${channelId}/members`;
export const TEAMS_CHANNEL_MESSAGES = (teamId: string, channelId: string) =>
  `${TEAMS_CHANNELS(teamId)}/${channelId}/messages`;
export const SEND_TEAMS_CHANNEL_MESSAGE = (teamId: string, channelId: string) =>
  `${TEAMS_CHANNELS(teamId)}/${channelId}/message`;
export const TEAMS_CHANNEL_REPLIES = (teamId: string, channelId: string, messageId: string) =>
  `${TEAMS_CHANNELS(teamId)}/${channelId}/messages/${messageId}/replies`;
export const SEND_TEAMS_CHANNEL_REPLY = (teamId: string, channelId: string, messageId: string) =>
  `${TEAMS_CHANNELS(teamId)}/${channelId}/messages/${messageId}/reply`;
export const TEAMS_CHANNEL_FILES = (teamId: string, channelId: string) =>
  `${TEAMS_CHANNELS(teamId)}/${channelId}/files`;

export const USERS = () => `${TEAMS_CONTEXT_URL}/users`;

export const CHATS = (): string => `${TEAMS_CONTEXT_URL}/chats`;
export const CHATS_ONE_TO_ONE = (): string => `${CHATS()}/onetoone`;
export const CHATS_GROUP = (): string => `${CHATS()}/group`;
export const CHAT_MESSAGES = (chatId: string): string => `${CHATS()}/${chatId}/messages`;
export const CHAT_ADD_MEMBER = (chatId: string): string => `${CHATS()}/${chatId}/addMember`;
export const SEND_CHAT_MESSAGE = (chatId: string): string => `${CHATS()}/${chatId}/messages`;
export const USER_STATUS = (): string => `${TEAMS_CONTEXT_URL}/me/presence`;
export const OTHER_USER_STATUS = (adoId: string) => `${TEAMS_CONTEXT_URL}/users/${adoId}/presence`;

// Popover
export const USER = (): string => `${TEAMS_CONTEXT_URL}/user`;
export const USER_INFO = (userId: string): string => `${USER()}/${userId}`;
export const PROFILE_INFO = (userId: string): string => `${PROFILE()}/adOid/${userId}/card`;
export const PROFILE_INFO_BY_EMAIL = (email: string): string => `${PROFILE()}/email/${email}/card`;
export const PROFILE_INFO_CARD = (): string => `${PROFILE()}/card`;
export const PROFILE_INFO_STATUS = (): string => `${PROFILE()}/status`;

// Drive
export const ONE_DRIVE_SHARED_LIBRARIES = (groupId: string) =>
  `${MS_TEAMS_ONE_DRIVE_CONTEXT}/sharedLibraries/${groupId}`;
export const FILE_IN_GROUP_FOLDER = (groupId: string, itemId: string) =>
  `${MS_TEAMS_ONE_DRIVE_CONTEXT}/groups/${groupId}/items/${itemId}`;
export const SHARED_WITH_ME = (): string => `${MS_TEAMS_ONE_DRIVE_CONTEXT}/sharedWithMe`;
export const CREATE_NEW_FOLDER_IN_GROUP = (
  groupId: string,
  parentFolderId: string,
  folderName: string
) =>
  `${MS_TEAMS_ONE_DRIVE_CONTEXT}/groups/${groupId}/drive/items/${parentFolderId}/children/${folderName}`;
export const CREATE_UPLOAD_SESSION_FOR_TEAM = (groupId: string, itemId: string) =>
  `${MS_TEAMS_ONE_DRIVE_CONTEXT}/groups/${groupId}/items/${itemId}/createUploadSession`;
export const SEARCH_FILES = (): string => `${MS_TEAMS_ONE_DRIVE_CONTEXT}/me/search`;
export const SHARED_FOLDER = (driveId: string, itemId: string) =>
  `${MS_TEAMS_ONE_DRIVE_CONTEXT}/${driveId}/items/${itemId}/children`;

export const DRIVE_ITEM_CHILDREN = (folderId: string) =>
  `${MS_TEAMS_ONE_DRIVE_CONTEXT}/me/items/${folderId}/children`;
export const ROOT_FOLDER = () => `${MS_TEAMS_ONE_DRIVE_CONTEXT}/me/rootfolder`;
export const ROOT_FOLDER_CHILDREN = () => `${ROOT_FOLDER()}/children`;
export const CREATE_NEW_FOLDER_IN_USER = (parentFolderId: string, folderName: string) =>
  `${MS_TEAMS_ONE_DRIVE_CONTEXT}/me/drive/items/${parentFolderId}/children/${folderName}`;
export const CREATE_UPLOAD_SESSION_FOR_USER = (folderId: string) =>
  `${MS_TEAMS_ONE_DRIVE_CONTEXT}/items/${folderId}/createUploadSession`;
export const GET_RECENT_FILES = () => `${MS_TEAMS_ONE_DRIVE_CONTEXT}/me/recent`;
export const GET_DRIVE_ITEM = (driveId: string, itemId: string) =>
  `${MS_TEAMS_ONE_DRIVE_CONTEXT}/${driveId}/items/${itemId}`;

//Leaves
export const LEAVES_ALL_TYPES_LIST = () => `${EMPLOYEE_CONTEXT_URL}/absence`;
export const LEAVES_TYPE = (absenceId: string) => `${LEAVES_ALL_TYPES_LIST}/${absenceId}`;
export const PUBLIC_HOLIDAYS = () => `${EMPLOYEE_CONTEXT_URL}/attendance/public/holidays`;

//Attendance
export const ATTENDANCE_TIME_CARD = (hcmPersonId: string): string =>
  `${ATTENDANCE_CONTEXT_URL_V2}/${hcmPersonId}/timeCard`;
export const EXPORT_ATTENDANCE = (hcmPersonId: string): string =>
  `${ATTENDANCE_CONTEXT_URL_V2}/export/${hcmPersonId}`;
export const ATTENDANCE_LIST = (hcmPersonId: string, startDate: string, endDate: string) =>
  `${ATTENDANCE_CONTEXT_URL_V2}/${hcmPersonId}/timeCard/startDate/${startDate}/endDate/${endDate}`;
export const MY_TEAM_ATTENDANCE = (selectedDate: string) =>
  `${ATTENDANCE_CONTEXT_URL}/my-team/timeCard/${selectedDate}`;

//Dashboard
export const APPS = () => `${EMPLOYEE_CONTEXT_URL}/profile/app`;

export const DASHBOARD_USER_GUIDE = () => `${EMPLOYEE_CONTEXT_URL}/dashboard/user-guide`;

//Settings
export const NOTIFICATION_SETTINGS = () =>
  `${NOTIFICATION_CONTEXT_URL}/notifications/settings/preference`;

//Notification
export const NOTIFICATION_UNREAD_COUNTER = () =>
  `${NOTIFICATION_CONTEXT_URL}/notifications/get-unread-count`;

export const NOTIFICATION_LIST = () => `${NOTIFICATION_CONTEXT_URL}/notifications/latest`;

export const ALL_NOTIFICATION_UPDATES_LIST = (requestId: string) =>
  `${NOTIFICATION_CONTEXT_URL}/notifications/all-updates-of-request/${requestId}`;

export const MARK_NOTIFICATION_AS_READ = () => `${NOTIFICATION_CONTEXT_URL_V2}/notifications/read`;

//Employee Directory
export const EMPLOYEE_DIRECTORY = () => `${EMPLOYEE_CONTEXT_URL}/profile/allEmployees`;

//CMS
export const CMS_URL = () => `${EMPLOYEE_CONTEXT_URL}/cms`;
export const ALL_ANNOUNCEMENT_CARDS = () => `${CMS_URL()}/allCmsCards`;
export const ANNOUNCEMENT_IMAGE = (imagePath: string) =>
  `${CMS_URL()}/imageDownload?imageRef=${imagePath}`;

// Lease Sites
export const LEASE_SITES_FORM_DETAILS = () => `${LEASE_CONTEXT_URL}/sites/form/details`;
export const LEASE_DISTRICTS = (cityId: string) => `${LEASE_CONTEXT_URL}/districts/${cityId}`;
export const LEASE_BANK_BRANCHES = (bankId: string) =>
  `${LEASE_CONTEXT_URL}/banks/branches/${bankId}`;
export const LEASE_REQUEST_FORM_DETAILS = (processInstanceId: string) =>
  `${LEASE_CONTEXT_URL}/sites/requests/${processInstanceId}/field-runner/form/details`;
export const LEASE_CITIES = () => `${LEASE_CONTEXT_URL}/cities`;
export const LEASE_REQUEST = () => `${LEASE_CONTEXT_URL}/sites/request`;
export const LEASE_SITES_REQUEST_TASK = (taskId: string) =>
  `${LEASE_CONTEXT_URL}/sites/request/${taskId}`;
export const LEASE_SITES_ASSIGN_WO_TASK = (taskId: string) =>
  `${LEASE_CONTEXT_URL}/sites/request/assignWOTask/${taskId}`;
export const LEASE_SITES_ASSIGN_WO_TASK_FORM_DETAILS = () =>
  `${LEASE_CONTEXT_URL}/sites/request/assignWOTask/form/details`;
export const LEASE_SITES_REQUESTS_ASSIGN_WO_TASK = (processInstanceId: string) =>
  `${LEASE_CONTEXT_URL}/sites/requests/${processInstanceId}/assignWOTask`;
export const LEASE_CREATE_TASK_ATTACHMENT = (
  taskId: string,
  processInstanceId: string,
  processDefinitionId: string,
  activityId: string
) =>
  `${LEASE_CONTEXT_URL}/create-Attachment/${taskId}/${processInstanceId}/${activityId}/${processInstanceId}`;
export const LEASE_TASK_ATTACHMENT = (taskId: string, attachmentId: string) =>
  `${LEASE_CONTEXT_URL}/task/${taskId}/attachment/${attachmentId}`;
export const LEASE_SITES_LIST = () => `${LEASE_CONTEXT_URL}/sites/list-sites`;
export const LEASE_SITES_LIST_FILTER_LOOKUPS = () =>
  `${LEASE_CONTEXT_URL}/sites/lookups/filter-lookups`;
export const LEASE_SITES_REQUESTS_FIELD_RUNNER = (processInstanceId: string) =>
  `${LEASE_CONTEXT_URL}/sites/requests/${processInstanceId}/field-runner`;
export const LEASE_SITES_REQUESTS_FIELD_RUNNER_DRAFT = (processInstanceId: string) =>
  `${LEASE_CONTEXT_URL}/sites/requests/${processInstanceId}/field-runner/draft`;

//Google Maps
export const GET_GOOGLE_ADDRESS_FROM_LAT_LNG = () =>
  `https://maps.googleapis.com/maps/api/geocode/json`;

//ELibrary
export const ELIBRARY_DOCUMENT_TYPE = () => `${SHAREPOINT_URL}/document/types`;
export const ELIBRARY_SECTORS = () => `${SHAREPOINT_URL}/sectors`;
export const ELIBRARY_DEPARTMENTS_BY_SECTOR = (sectorName: string) =>
  `${SHAREPOINT_URL}/departments/${sectorName}`;
export const ELIBRARY_DOCUMENTS_BY_DEPARTMENT = (departmentName: string) =>
  `${SHAREPOINT_URL}/documents/${departmentName}`;
export const ELIBRARY_DOCUMENT_INFO = (documentID: string) =>
  `${SHAREPOINT_URL}/document/details/${documentID}`;
export const ELIBRARY_DOWNLOAD_DOCUMENT = (serverRelativeUrl: string) =>
  `${SHAREPOINT_URL}/document/download?serverRelativeURL=${serverRelativeUrl}`;
export const ELIBRARY_DOCUMENTS_FILTER = () => `${SHAREPOINT_URL}/documents/filter`;

//Ethics Code
export const ETHICS_CODE_CONTENT = () => `${EMPLOYEE_CONTEXT_URL}/ethics`;

//New profile
export const NEW_PROFILE = (): string => `${EMPLOYEE_CONTEXT_URL}/profile`;

export const NEW_PROFILE_PERSONAL_DATA = () => `${NEW_PROFILE()}/personal-details`;
export const NEW_PROFILE_EMPLOYMENT_DETAILS_WITHOUT_PAY_ROLL = () =>
  `${NEW_PROFILE()}/employment-details`;
export const NEW_PROFILE_BASIC_INFO = () => `${NEW_PROFILE()}/basicInfo`;
export const PROFILE_SET_NICKNAME = () => `${NEW_PROFILE()}/update-nickname`;
export const NEW_PROFILE_ALL_USER_DATA = () => `${NEW_PROFILE()}/details/all`;
