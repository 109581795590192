export const QUERIES = {
  allRequests: 'allRequests',
  request: 'request',
  taskFormData: 'taskFormData',
  newRequestForm: 'newRequestForm',
  categoriesTree: 'categoriesTree',
  allRequestCard: 'allRequestCard',
  subCategoryCard: 'subCategoryCard',
  categoryCard: 'categoryCard',
  requestCatalogDrawerInfo: 'requestCatalogDrawerInfo',
  payslipInfo: 'payslipInfo',
  payslipPDFFile: 'payslipPDFFile',
  dashboardRequestList: 'dashboardRequestList',
  requestDescription: 'requestDescription',
  myRequestWidget: 'myRequestWidget',
  processGroups: 'processGroups',
  requestGeneralInfo: 'requestGeneralInfo',
  completedTaskFormFields: 'completedTaskFormFields',
  allMemberInTheGroup: 'allMemberInTheGroup',
  removerMemberFromGroup: 'removerMemberFromGroup',
  addMemberToGroup: 'addMemberToGroup',
  expensesFormValues: 'expensesFormValues',
  dashboardApps: 'dashboardApps',
  userAvatarByEmail: 'userAvatarByEmail',
  selfServiceLetter: 'selfServiceLetter',
  businessTravel: 'businessTravel',
  expenseInfo: 'expenseInfo',
  jiraFormFields: 'jiraFormFields',
  attachmentStandard: 'attachmentStandard',
  myTeamLeaveList: 'myTeamLeaveList',
  attendanceTimeCard: 'attendanceTimeCard',
  exportAttendanceReport: 'exportAttendanceReport',
  myTeamMembersList: 'myTeamMembersList',
  creditCardDetail: 'creditCardDetail',
  myLeaveDrawer: 'myLeaveDrawer',
  myTeamLeaveDrawer: 'myTeamLeaveDrawer',
  expenseReimbursement: 'expenseReimbursement',
  notificationSettings: 'notificationSettings',
  attendanceList: 'attendanceList',
  countryCityList: 'countryCityList',
  handleDistance: 'handleDistance',
  unreadNotification: 'unreadNotification',
  myTeamAttendance: 'myTeamAttendance',
  siteIds: 'siteIds',
  travelDuration: 'travelDuration',
  estimatedTotalAmount: 'estimatedTotalAmount',
  cashAdvanceDetail: 'cashAdvanceDetail',
  fetchCustomersName: 'fetchCustomersName',
  scrapInvoiceFormDetails: 'scrapInvoiceFormDetails',
  homeLoanInterest: 'homeLoanInterest',
  fetchEmployeesName: 'fetchEmployeesName',
  getUserStatus: 'getUserStatus',
  getDelegationFormDetails: 'getDelegationFormDetails',
  getDelegatorsFilterNames: 'getDelegatorsFilterNames',
  getLeavesDelegators: 'getLeavesDelegators',
  leaseSitesFormDetails: 'leaseSitesFormDetails',
  leaseCities: (regionId: string) => ['leaseCities', `${regionId}`],
  leaseDistricts: (regionId: string) => ['leaseDistricts', `${regionId}`],
  leaseBankBranches: (bankId: string) => ['leaseBankBranches', `${bankId}`],
  leaseSitesRequestsFormDetails: (processInstanceId: string) => [
    'leaseSitesRequestsFormDetails',
    `${processInstanceId}`,
  ],
  leaseAssigneeTypes: () => ['leaseAssigneeTypes'],
  getLeaseSitesFilterLookups: 'getLeaseSitesFilterLookups',
  getLeaseSitesSubmittedCandidatesForm: (taskId: string) => [
    'getLeaseSitesSubmittedCandidatesForm',
    `${taskId}`,
  ],
  getLeaseSitesCandidatesFormDraft: (taskId: string) => [
    'getLeaseSitesCandidatesFormDraft',
    `${taskId}`,
  ],
  getLeavesAssignedDelegators: 'getLeavesAssignedDelegators',
  jiraAssetsList: 'jiraAssetsList',
  eLibrarySectors: 'eLibrarySectors',
  eLibraryDepartments: 'eLibraryDepartments',
  eLibraryDocuments: 'eLibraryDocuments',
  eLibraryDocumentsFilter: 'eLibraryDocumentsFilter',
  eLibraryDocumentInfo: 'ELibraryDocumentInfo',
  eLibraryDocumentTypes: 'eLibraryDocumentTypes',
  eLibraryDocumentDownload: 'ELibraryDocumentDownload',
  ethicsCodeContent: 'ethicsCodeContent',
  profilePersonalData: 'profilePersonalData',
  profileEmploymentDetail: 'profileEmploymentDetail',
  profileBasicInfo: 'profileBasicInfo',
  publicProfileInfo: 'publicProfileInfo',
  profileOnGoingRequest: 'profileOnGoingRequest',
  publicProfileDelegateInfo: 'publicProfileDelegateInfo',
  profileUpdateRequestData: 'profileUpdateRequestData',
  profileUpdateRequestLookups: 'profileUpdateRequestLookups',
  changeBankAccountRequestLookUp: 'changeBankAccountRequestLookUp',
  changeBankAccountRequestSubmit: 'changeBankAccountRequestSubmit',
  nonPoInvoiceLookupsList: 'nonPoInvoiceLookupsList',
  nonPoInvoiceConversionRate: 'nonPoInvoiceConversionRate',
  nonPoInvoiceWitholdingTaxRate: 'nonPoInvoiceWitholdingTaxRate',
  nonPoInvoiceGetEditRequest: 'nonPoInvoiceGetEditRequest',
  nonPoInvoiceUpdateRequest: 'nonPoInvoiceUpdateRequest',
  getNonPoBudgetGetRequestType: 'getNonPoBudgetGetRequestType',
  getNonPoBudgetGlAccounts: 'getNonPoBudgetGlAccounts',
  getNonPoBudgetCostCenterAccounts: 'getNonPoBudgetCostCenterAccounts',
  getNonPoBudgetProjectAccounts: 'getNonPoBudgetProjectAccounts',
  getPublicHolidays: 'getPublicHolidays',
  getWorkFromHomeRemainingBalance: 'getWorkFromHomeRemainingBalance',
  dashboardUserGuide: 'dashboardUserGuide',
};

export const PERSIST_QUERY_CONFIG = {
  refetchOnMount: false,
  staleTime: 100000,
  cacheTime: 100000,
  refetchOnWindowFocus: false,
};
