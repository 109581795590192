import styled, { css } from 'styled-components';
import { Card, Col, Typography } from 'antd';
import { borderRadius, colors, space } from 'ui/elements';
import { UserGuideDownload, UserGuideFileIcon } from 'assets';

const { Text } = Typography;

export const CardUserGuideStyle = css`
  border-radius: ${borderRadius.xLarge};
  margin-right: ${space.moderate};
  box-shadow: 0 2px 4px rgba(28, 43, 101, 0.14);
  border: none;
  cursor: pointer;
`;

export const CardUserGuide = styled(Card)`
  ${CardUserGuideStyle}
`;

export const AttachmentInfoContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: ${space.moderate};
  padding-right: ${space.moderate};
`;

export const AttachmentNameUserGuideCard = styled(Text)`
  font-size: 18px;
  font-weight: 500;
  line-height: 21.48px;
  letter-spacing: 0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: ${colors.primaryColor};
`;
export const AttachmentTimeUserGuideCard = styled(Text)`
  font-size: 12px;
  font-weight: 400;
  line-height: 14.32px;
  letter-spacing: 0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: ${colors.textColorSecondary};
`;

export const AttachmentIconUserGuideCard = styled(UserGuideFileIcon)`
  width: 24px;
  height: 24px;
`;
export const DownloadIconUserGuideCard = styled(UserGuideDownload)``;
