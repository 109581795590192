import { Core, APPS, DASHBOARD_USER_GUIDE } from 'api';
import { ApplistItem, DashboardUserGuideDto } from 'types';

interface GetDashboardApps {
  (): Promise<{ data: ApplistItem[] }>;
}

interface GetDashboardUserGuide {
  (): Promise<{ data: DashboardUserGuideDto }>;
}

class DasboardApps extends Core {
  getDasboardApps: GetDashboardApps = async () => this.get(APPS());
  getDashboardUserGuide: GetDashboardUserGuide = async () => this.get(DASHBOARD_USER_GUIDE());
}

export const dashboardApps = new DasboardApps();
