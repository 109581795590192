export * from './Settings';
export * from './ExternalUser';
export * from './RequestStatus';
export * from './RequestCommonError';
export * from './Site';
export * from './SiteIncident';
export * from './Profile';
export * from './Chats';
export * from './UserStatus';
export * from './CompanyInfo';
export * from './Sort';
export * from './Comment';
export * from './Attachment';
export * from './Asset';
export * from './Photo';
export * from './UserApproval';
export * from './Incident';
export * from './TicketStatus';
export * from './ResponsePageable';
export * from './Hierarchy';
export * from './Subordinates';
export * from './UserAvatar';
export * from './GeneralInfo';
export * from './Teams';
export * from './TeamsChannels';
export * from './UserInfoData';
export * from './TeamsChannelMembers';
export * from './TaskStatus';
export * from './FIlesList';
export * from './FolderList';
export * from './Users';
export * from './SharedWithMe';
export * from './OneDriveSharedLibraries';
export * from './OneDriveSharedLibraryList';
export * from './OneDriveSharedLibraryNewFolder';
export * from './uploadSelectedFile';
export * from './ProcessDefinitionKeys';
export * from './ProcessDefinition';
export * from './Task';
export * from './OneDriveSearchResult';
export * from './RootFolder';
export * from './OneDriveItemChildren';
export * from './OneDriveFolder';
export * from './RootFolderChildren';
export * from './OneDriveCreateNewFolderInUser';
export * from './CreateUploadSessionForUser';
export * from './RecentFiles';
export * from './GetDriveItem';
export * from './ReassignTask';
export * from './ProcessInstanceInfo';
export * from './TaskDetails';
export * from './TaskDiagram';
export * from './TaskComments';
export * from './Tasks';
export * from './TaskAssignmnt';
export * from './UserGroup';
export * from './CamundaAttachment';
export * from './processDefinitionList';
export * from './AssigneeUser';
export * from './GroupMembers';
export * from './Leaves';
export * from './HistoricActivityInstance';
export * from './Expense';
export * from './CamundaUser';
export * from './Balance';
export * from './LeavesHistory';
export * from './LeavesStatus';
export * from './PaySlip';
export * from './Group';
export * from './TaskFiles';
export * from './PublicHolidays';
export * from './AttendanceInfo';
export * from './CategoriesTree';
export * from './RequestCards';
export * from './ServicesCards';
export * from './RequestsCatalogDrawer';
export * from './SelfServiceLetter';
export * from './DashboardRequestsCard';
export * from './GroupTaskStatus';
export * from './ProcessManagements';
export * from './RequestSteps';
export * from './DashboardApps';
export * from './BusinessTrainingTravel';
export * from './FormItem.type';
export * from './JiraFormData';
export * from './ExpenseStatus';
export * from './CreditCard';
export * from './NotificationSettings';
export * from './MyTeamList';
export * from './CountryCityList';
export * from './NotificationCenter';
export * from './NotificationDrawer';
export * from './CashAdvance';
export * from './ScrapInvoiceRequest';
export * from './HomeLoan';
export * from './ActingAppointment';
export * from './EmplyeeDirectoryUser';
export * from './Announcements';
export * from './DelegationFormRequest';
export * from './JiraDynamicForm';
export * from './DelegatorsFilterList';
export * from './LeaseSites';
export * from './LoVs';
export * from './GeneratedForm.types';
export * from './LeaseSitesCandidate';
export * from './GoogleMapsApi';
export * from './SiteAcquisitionTaskSubmitForm.constants';
export * from './ELibrary';
export * from './EthicsCode';
export * from './NewProfile';
export * from './ProfileUpdateRequest';
export * from './ChangeBankAccountRequestType';
export * from './NONPoInvoiceType';
export * from './WorkFromHomeType';
export * from './DashboardUserGuiide';

declare global {
  interface Window {
    less: {
      modifyVars(obj: { [key: string]: string }): Promise<unknown>;
    };
  }
}
