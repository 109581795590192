import React, { useMemo, useRef, useState } from 'react';
import { CardCarousel, CarouselWrapper } from 'ui/components/CardCarousel';
import { CarouselRef } from 'antd/lib/carousel';
import { DashboardUserGuideAttachmentDto } from 'types';
import { useDashboardUserGuide } from 'api';
import moment from 'moment';
import { UserGuideCard } from './Components';

export interface DigitalUserGuideCarouselProps {
  userAdoID: string;
}

const DEFAULT_SLIDES_SHOW = 2.75;

const DigitalUserGuideCarouselComponent: React.FC<DigitalUserGuideCarouselProps> = () => {
  const [scrollsCount, setScrollsCount] = useState<number>(0);
  const carouselEl = useRef<CarouselRef>(null);
  const { data, isLoading, isSuccess } = useDashboardUserGuide();

  const beforeChange = (currentSlide: number, nextSlide: number) => setScrollsCount(nextSlide);

  const cardsLength = useMemo(() => {
    return data && isSuccess ? data.attachments.length : 0;
  }, [data, isSuccess]);

  /**
   * Check is today
   * fall into display data range or not
   */
  const showCarousel = useMemo(() => {
    if (!isSuccess || !data) {
      return false;
    }
    const { displayFromDate, displayToDate } = data;
    const currentDate = moment();
    return currentDate.isBetween(displayFromDate, displayToDate, null, '[]');
  }, [data, isSuccess]);

  const Carousel = useMemo(
    () => (
      <CarouselWrapper
        carouselTitle={data?.sectionName || ''}
        emptyDescription={''}
        isLoading={isLoading}
        cardLength={cardsLength}
        carouselEL={carouselEl}
        scrollsCount={scrollsCount}
      >
        <CardCarousel
          carouselEL={carouselEl}
          beforeChange={beforeChange}
          slidesShow={DEFAULT_SLIDES_SHOW}
        >
          {data?.attachments.map((attachment: DashboardUserGuideAttachmentDto, index) => (
            <UserGuideCard key={index} dashboardGuideAttachment={attachment} />
          ))}
        </CardCarousel>
      </CarouselWrapper>
    ),
    [cardsLength, data, isLoading, scrollsCount]
  );

  return showCarousel ? Carousel : null;
};

export const DigitalUserGuideCarousel = React.memo(DigitalUserGuideCarouselComponent);
