import React, { FC } from 'react';

import { AppLayout } from 'layouts';
import { Header } from 'app';
import { TType, withTranslate } from 'translations';
import { colors, OneDriveWidget } from 'ui';
import { Col, Row } from 'antd';
import { getCurrentUserAdoid, getUserFirstName, useSelector } from 'store';
import { AppsWidget, CmsCarousel, TaskCarousel, UserName, YourRequestWidget } from 'routes';
import {
  StyledRowLayout,
  StyledDashboardCard,
  StyledCMSCarouselContainer,
} from './DashboardStyled';
import { DigitalUserGuideCarousel } from './Components/templates/DigitalUserGuideCarousel';

interface Props {
  t: TType;
}

const DISABLE_ANNOUNCEMENT_CARDS = process.env.REACT_APP_DISABLE_ANNOUNCEMENT_CARDS === 'true';
export const Dashboard: FC<Props> = withTranslate(({ t }) => {
  const userName = useSelector(getUserFirstName);
  const userAdoid = useSelector(getCurrentUserAdoid);

  return (
    <AppLayout displayFooter={false}>
      <Header />

      <Row className="layout-main">
        <Col span={18}>
          <StyledRowLayout>
            <Col>
              <UserName userName={userName} />
            </Col>
          </StyledRowLayout>
          <StyledRowLayout gutter={[0, 20]}>
            {userAdoid && (
              <Col span={24}>
                <DigitalUserGuideCarousel userAdoID={userAdoid} />
              </Col>
            )}
            {!DISABLE_ANNOUNCEMENT_CARDS && (
              <StyledCMSCarouselContainer span={24}>
                <CmsCarousel />
              </StyledCMSCarouselContainer>
            )}
            {userAdoid && (
              <Col span={24}>
                <TaskCarousel userAdoID={userAdoid} />
              </Col>
            )}
          </StyledRowLayout>
        </Col>

        <Col span={6}>
          <StyledDashboardCard bgColor={colors.white}>
            {userAdoid && <YourRequestWidget userId={userAdoid} />}
            <AppsWidget />
            <OneDriveWidget />
          </StyledDashboardCard>
        </Col>
      </Row>
    </AppLayout>
  );
});
