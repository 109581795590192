import React from 'react';
import { Row } from 'antd';
import { DashboardUserGuideAttachmentDto } from 'types';
import moment from 'moment/moment';
import {
  AttachmentIconUserGuideCard,
  AttachmentInfoContainer,
  AttachmentNameUserGuideCard,
  AttachmentTimeUserGuideCard,
  CardUserGuide,
  DownloadIconUserGuideCard,
} from './UserGuideStyles';

export interface UserGuideCardComponentProps {
  dashboardGuideAttachment: DashboardUserGuideAttachmentDto;
}
const UserGuideCardComponent: React.FC<UserGuideCardComponentProps> = ({
  dashboardGuideAttachment,
}: UserGuideCardComponentProps) => {
  const openLink = () => {
    window.open(dashboardGuideAttachment.url, '_blank');
  };

  return (
    <CardUserGuide>
      <Row align="middle">
        <AttachmentIconUserGuideCard />
        <AttachmentInfoContainer>
          <AttachmentNameUserGuideCard>
            {dashboardGuideAttachment?.fileName}
          </AttachmentNameUserGuideCard>
          <AttachmentTimeUserGuideCard>
            {moment(dashboardGuideAttachment?.displayDate).format('MMM DD, YYYY')}
          </AttachmentTimeUserGuideCard>
        </AttachmentInfoContainer>
        <DownloadIconUserGuideCard onClick={openLink} />
      </Row>
    </CardUserGuide>
  );
};

export const UserGuideCard = React.memo(UserGuideCardComponent);
