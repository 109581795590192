import { useQuery } from 'react-query';
import { dashboardApps } from '../DashboardApps';
import { PERSIST_QUERY_CONFIG, QUERIES } from '../../constants';

export function useDashboardUserGuide() {
  return useQuery(
    [QUERIES.dashboardUserGuide],
    async () => {
      const { data } = await dashboardApps.getDashboardUserGuide();
      return data;
    },
    PERSIST_QUERY_CONFIG
  );
}
